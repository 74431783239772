import React, { useEffect, useState } from "react";
import "./App.css";
import "./index.css";
import {
  BrowserRouter as Router,
  Navigate,
  Outlet,
  Route,
  Routes,
  useLocation,
} from "react-router-dom";
import AppRoutes from "./structure/routes";
import { Home } from "./pages/Home/Home";
import { About } from "./pages/About/About";
import { Archive } from "./pages/Archive/Archive";
import { Certificates } from "./pages/Certificates/Certificates";
import { Command } from "./pages/Command/Command";
import { Courses } from "./pages/Courses/Courses";
import { Feedback } from "./pages/Feedback/Feedback";
import { Learn } from "./pages/Learn/Learn";
import { Level } from "./pages/Level/Level";
import { Main } from "./pages/Main/Main";
import { Order } from "./pages/Order/Order";
import { Recovery } from "./pages/Recovery/Recovery";
import { Registration } from "./pages/Registration/Registration";
import { ResetPassword } from "./pages/ResetPassword/ResetPassword";
import { Session } from "./pages/Session/Session";
import { Shop } from "./pages/Shop/Shop";
import { ShopItem } from "./pages/ShopItem/ShopItem";
import { Subscription } from "./pages/Subscription/Subscription";
import { UserCabinet } from "./pages/UserCabinet/UserCabinet";
import { UserPage } from "./pages/UserPage/UserPage";
import PrizesForSystemepoints from "./pages/PrizesForSystemepoints/PrizesForSystemepoints";
import Auth from "./pages/Auth/Auth";
import TopNav from "./components/TopNav";
import MainNav from "./components/MainNav";
import Sidebar from "./components/Sidebar";
import Footer from "./components/Footer";
import { GetRecomendationsRes } from "./network/CourseApi/courseInterfaces";
import { getRecomendations } from "./network/CourseApi/courseApi";
import Vebinar from "./pages/Vebinar/Vebinar";
import Info from "./pages/Info/Info";
import { getHeader } from "./network/UserApi/userApi";
import { Create } from "./pages/Create/Create";
import Welcome from "./pages/Welcome/Welcome";
import PrizeOfFirstProject from "./pages/PrizeOfFirstProject/PrizeOfFirstProject";
import ProjectRegistration from "./pages/ProjectRegistration/ProjectRegistration";
import SendQuestionPage from "./pages/SendQuestionPage/SendQuestionPage";
import PromoFooter from "./components/PromoFooter";
import PromoRegistration from "./pages/PromoRegistrtion/PromoRegistration";
import { PromoComtextProvider } from "./components/PromoComtextProvider";
import PromoRegistrationFinal from "./pages/PromoRegistrtion/PromoRegistrationFinal";
import { PromoType, UserPromoRole } from "./network/PromoApi/promoInterfaces";
import { useAuth } from "./Providers/PromoUserProvider";
import PromoCabinet from "./pages/PromoCabinet/PromoCabinet";
import { promoUserInfo } from "./network/PromoApi/promoApi";
import PromoAdmn from "./pages/PromoAdmin/PromoAdmn";
import { OldCreate } from "./pages/OldCreate/OldCreate";
import BasketPage from "./pages/Basket/BasketPage";
import OrderBasketPage from "./pages/OrderBasketPage/OrderBasketPage";

type IProps = {
  lauraRender: boolean;
  recomendationsRender: boolean;
  certificatesRender: boolean;
  buyRender: boolean;
};

const Layout = (p: IProps) => {
  const [recomendations, setRecomendations] = useState<
    Array<GetRecomendationsRes>
  >([]);

  useEffect(() => {
    getRecomendations().then((r) => {
      setRecomendations(r);
    });
  }, []);

  const navLinks = [
    { appRoute: AppRoutes.Main, linkName: "Главная страница" },
    { appRoute: AppRoutes.UserCabinet, linkName: "Учебный кабинет" },
    { appRoute: AppRoutes.Courses, linkName: "Каталог курсов" },
    { appRoute: AppRoutes.Certificates, linkName: "Сертификаты" },
    { appRoute: AppRoutes.Level, linkName: "Награды и уровни" },
    {
      appRoute: AppRoutes.Create,
      linkName: `Акция Развивай SYSTEME`,
      textStyle: `baseBold`,
    },
    { appRoute: AppRoutes.About, linkName: "О платформе" },
    { appRoute: AppRoutes.Feedback, linkName: "Обратная связь" },
    { appRoute: AppRoutes.Subscription, linkName: "Подписка" },
  ];
  return (
    <>
      <PromoComtextProvider>
        <TopNav />
        <div className="relative max-w-[1440px] m-auto p-6 flex justify-between">
          <MainNav navLinks={navLinks} />
          <div className="ml-[240px] w-full pr-6 pb-[80px]">
            <Outlet />
          </div>
          <Sidebar
            buyRender={p.buyRender}
            lauraRender={p.lauraRender}
            recomendationsRender={p.recomendationsRender}
            certificatesRender={p.certificatesRender}
            recomendations={recomendations}
          />
        </div>
        <Footer />
      </PromoComtextProvider>
    </>
  );
};

const EmptuLayout = () => {
  return (
    <PromoComtextProvider>
      <div className="w-full min-h-[117.6vh] flex flex-col justify-between">
        <div className="sm:mx-0 mx-[15%]">
          <Outlet />
        </div>
        <PromoFooter />
      </div>
    </PromoComtextProvider>
  );
};

function PageRouter() {
  const location = useLocation();

  const [userInfo, setUserInfo, getUserInfo] = useAuth();
  const [isAuth, setIsAuth] = useState<boolean>();

  const onPromoPages = [AppRoutes.PromoCabinet].includes(
    location.pathname as AppRoutes
  );

  console.log(onPromoPages);

  useEffect(() => {
    getHeader().then((r) => {
      setIsAuth(!r.auth_error);
    });
    getUserInfo();
  }, []);

  return (
    <Routes>
      {userInfo !== undefined &&
        (userInfo.role !== UserPromoRole.ADMIN ? (
          <Route path={AppRoutes.PromoCabinet} element={<PromoCabinet />} />
        ) : (
          <Route path={AppRoutes.PromoAdmn} element={<PromoAdmn />} />
        ))}
      {!isAuth ? (
        <>
          {!onPromoPages && (
            <Route
              path="*"
              element={<Navigate to="/welcome" replace={true} />}
            />
          )}
          <Route
            path={AppRoutes.Welcome}
            element={<Welcome onAuth={setIsAuth} />}
          ></Route>
          <Route
            path={AppRoutes.Auth}
            element={<Auth onAuth={setIsAuth} />}
          ></Route>
          <Route path={AppRoutes.Recovery} element={<Recovery />}></Route>
          <Route
            path={AppRoutes.ResetPassword}
            element={<ResetPassword />}
          ></Route>
          <Route element={<EmptuLayout />}>
            <Route
              path={AppRoutes.PrizeOfFirstProject}
              element={<PrizeOfFirstProject />}
            ></Route>
            <Route
              path={AppRoutes.PrizesForSystemepoints}
              element={<PrizesForSystemepoints />}
            ></Route>
            {/* <Route
              path={AppRoutes.CreateOne}
              element={<Create promoType={PromoType.ONE} />}
            ></Route> */}
            {/* <Route
              path={AppRoutes.CreateTwo}
              element={<Create promoType={PromoType.TWO} />}
            ></Route>
            <Route
              path={AppRoutes.CreateThree}
              element={<Create promoType={PromoType.THREE} />}
            ></Route> */}

            <Route path={AppRoutes.Create} element={<OldCreate />}></Route>
            <Route
              path={AppRoutes.PromoRegistration}
              element={<PromoRegistration />}
            ></Route>
            <Route
              path={AppRoutes.PromoRegistrationFinal}
              element={<PromoRegistrationFinal />}
            ></Route>
            <Route path={AppRoutes.Basket} element={<BasketPage />}></Route>
            <Route
              path={AppRoutes.BasketOrder}
              element={<OrderBasketPage />}
            ></Route>
          </Route>
        </>
      ) : (
        <>
          <Route
            path="/"
            element={
              <Layout
                buyRender={false}
                lauraRender={true}
                certificatesRender={true}
                recomendationsRender={true}
              />
            }
          >
            <Route path={AppRoutes.Archive} element={<Archive />}></Route>
            <Route
              path={AppRoutes.Certificates}
              element={<Certificates />}
            ></Route>
            <Route path={AppRoutes.Command} element={<Command />}></Route>
            <Route path={`${AppRoutes.Courses}`} element={<Courses />}></Route>
            <Route path={AppRoutes.Feedback} element={<Feedback />}></Route>
            <Route path={AppRoutes.Level} element={<Level />}></Route>
            <Route path={AppRoutes.Main} element={<Main />}></Route>
            <Route path={AppRoutes.Order} element={<Order />}></Route>
            <Route
              path={AppRoutes.Registration}
              element={<Registration />}
            ></Route>
            <Route path={AppRoutes.Session} element={<Session />}></Route>
            <Route path={AppRoutes.ShopItem} element={<ShopItem />}></Route>
            <Route
              path={AppRoutes.UserCabinet}
              element={<UserCabinet />}
            ></Route>
          </Route>
          <Route
            path="/"
            element={
              <Layout
                buyRender={false}
                lauraRender={false}
                recomendationsRender={false}
                certificatesRender={false}
              />
            }
          >
            {/*Рендер без Лоры, рекомендаций и сертификатов*/}
            <Route
              path={AppRoutes.Question}
              element={<SendQuestionPage />}
            ></Route>
            <Route
              path={AppRoutes.ProjectRegistration}
              element={<ProjectRegistration />}
            ></Route>
            <Route
              path={AppRoutes.PrizeOfFirstProject}
              element={<PrizeOfFirstProject />}
            ></Route>
            <Route
              path={AppRoutes.PrizesForSystemepoints}
              element={<PrizesForSystemepoints />}
            ></Route>
            <Route path={AppRoutes.UserPage} element={<UserPage />}></Route>
            <Route path={AppRoutes.Learn} element={<Learn />}></Route>

            <Route path={AppRoutes.Create} element={<OldCreate />}></Route>
            <Route
              path={AppRoutes.PromoRegistration}
              element={<PromoRegistration />}
            ></Route>
            <Route
              path={AppRoutes.PromoRegistrationFinal}
              element={<PromoRegistrationFinal />}
            ></Route>
            <Route
              path={AppRoutes.PromoRegistration}
              element={<PromoRegistration />}
            ></Route>
            <Route
              path={AppRoutes.PromoRegistrationFinal}
              element={<PromoRegistrationFinal />}
            ></Route>
            <Route path={AppRoutes.Basket} element={<BasketPage />}></Route>
            <Route
              path={AppRoutes.BasketOrder}
              element={<OrderBasketPage />}
            ></Route>
          </Route>

          <Route
            path="/"
            element={
              <Layout
                buyRender={false}
                lauraRender={true}
                recomendationsRender={false}
                certificatesRender={false}
              />
            }
          >
            {/*Рендер без рекомендаций и сертификатов*/}
            <Route
              path={AppRoutes.Subscription}
              element={<Subscription />}
            ></Route>
            <Route path={AppRoutes.About} element={<About />}></Route>
            <Route path={AppRoutes.Vebinar} element={<Vebinar />}></Route>
          </Route>
          <Route
            path="/"
            element={
              <Layout
                buyRender={true}
                lauraRender={false}
                recomendationsRender={false}
                certificatesRender={false}
              />
            }
          >
            {/*Рендер с меню покупки*/}
            <Route path={AppRoutes.Info} element={<Info />}></Route>
          </Route>
          {/* <Route path={AppRoutes.Home} element={<Home />}></Route> */}
        </>
      )}
      <Route path="/welcome" element={<Navigate to="/" replace={true} />} />
    </Routes>
  );
}

export default PageRouter;
